// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/fetchUtils.tsx"
);
import.meta.hot.lastModified = "1709112347291.6187";
}
// REMIX HMR END

import moment from "moment-timezone";

export async function redisSet(key: string, value: any, ttl?: number) {
  return new Promise((resolve, reject) => {
    fetch("/api/redis/set", {
      method: "POST",
      headers: {
        "Content-Type": `application/json`,
      },
      body: JSON.stringify({ key, value, ttl }),
    }).then((response) => resolve(response.json()));
  });
}

export async function redisScan(key: string) {
  return new Promise((resolve, reject) => {
    fetch("/api/redis/scan", {
      method: "POST",
      headers: {
        "Content-Type": `application/json`,
      },
      body: JSON.stringify({ key }),
    }).then((response) => resolve(response.json()));
  });
}

export async function redisGet(key: string, raw?: boolean) {
  return new Promise((resolve, reject) => {
    fetch("/api/redis/" + (raw ? "getRaw" : "get"), {
      method: "POST",
      headers: {
        "Content-Type": `application/json`,
      },
      body: JSON.stringify({ key }),
    }).then((response) => resolve(response.json()));
  });
}

export async function redisDel(key: string) {
  return new Promise((resolve, reject) => {
    fetch("/api/redis/delete", {
      method: "POST",
      headers: {
        "Content-Type": `application/json`,
      },
      body: JSON.stringify({ key }),
    }).then((response) => resolve(response.json()));
  });
}

export async function addImage(
  imageURL: string,
  documentID?: string,
  ref?: string,
  data?: any,
  path = "deal"
) {
  const imageForm = new FormData();
  const response = await fetch(imageURL);
  const blob = await response.blob();
  imageForm.append(
    "files",
    blob,
    "deal-images_" + moment().format("YYYY-MM-DDTHH:mm:ss")
  ); // Produktname statt Datum!);
  imageForm.append("path", path);

  if (documentID) {
    imageForm.append("ref", ref || "api::deal.deal");
    imageForm.append("refId", documentID);
    imageForm.append("field", "titleImage");
  }

  const customData = data;
  if (customData?.imageURL && typeof imageURL == "string" && imageURL.startsWith("http")) {
    customData.imageURL = imageURL;
  } 

  if(customData) {
    imageForm.append("fileInfo", JSON.stringify({caption: JSON.stringify(customData)}));
  } else {
    imageForm.append("fileInfo", JSON.stringify({caption: JSON.stringify({path})}));
  }

  return await fetchFromBackend({
    method: FETCH_METHODS.UPLOAD,
    body: imageForm,
  })
    .then(async (result: any) => {
      return result[0];
    })
    .catch((e) => console.log(e));
}

export async function addFile(
  file: Blob,
  documentID?: string,
  ref?: string,
  field?: string,
  data?: any,
  path = "file"
) {
  const dataForm = new FormData();
  dataForm.append(
    "files",
    file
  ); // Produktname statt Datum!);

  dataForm.append("path", path);

  if (documentID && ref && field) {
    dataForm.append("ref", ref);
    dataForm.append("refId", documentID);
    dataForm.append("field", field);
  }

  const customData = data;

  if(customData) {
    dataForm.append("fileInfo", JSON.stringify({caption: JSON.stringify(customData)}));
  } else {
    dataForm.append("fileInfo", JSON.stringify({caption: JSON.stringify({path})}));
  }

  return await fetchFromBackend({
    method: FETCH_METHODS.UPLOAD,
    body: dataForm,
  })
    .then(async (result: any) => {
      return result[0];
    })
    .catch((e) => console.log(e));
}

export async function deleteImage(documentID?: string) {
  return await fetchFromBackend({
    method: FETCH_METHODS.DELETE,
    id: documentID,
    collectionType: encodeURIComponent("upload/files"),
  })
    .then(async (result: any) => {
      return result;
    })
    .catch((e) => console.log(e));
}

export enum FETCH_METHODS {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
  ENTRY = "entry",
  ENTRIES = "entries",
  UPLOAD = "upload",
}

export interface FetchFromBackendProps {
  method: string;
  collectionType?: string;
  body?: any;
  id?: string;
  params?: any;
}

export const fetchFromBackend = async (props: FetchFromBackendProps) => {
  const { method, collectionType, body, id, params } = props;
  return new Promise((resolve, reject) => {
    switch (method) {
      case FETCH_METHODS.UPLOAD:
        fetch("/api/strapi/upload/file", {
          method: "post",
          body,
        })
          .then((response) => response.json())
          .then(async (entry: any) => {
            resolve(entry);
          })
          .catch((e) => reject(e));
        break;
      case FETCH_METHODS.CREATE:
        fetch("/api/strapi/create/" + collectionType, {
          method: "post",
          headers: {
            "Content-Type": `application/json`,
          },
          body: JSON.stringify(body),
        })
          .then((response) => response.json())
          .then(async (entry: any) => {
            resolve(entry);
          })
          .catch((e) => reject(e));
        break;
      case FETCH_METHODS.UPDATE:
        fetch("/api/strapi/update/" + collectionType + "/" + id, {
          method: "post",
          headers: {
            "Content-Type": `application/json`,
          },
          body: JSON.stringify(body),
        })
          .then((response) => response.json())
          .then(async (entry: any) => {
            resolve(entry);
          })
          .catch((e) => reject(e));
        break;
      case FETCH_METHODS.DELETE:
        fetch("/api/strapi/delete/" + collectionType + "/" + id, {
          method: "post",
          headers: {
            "Content-Type": `application/json`,
          },
        })
          .then((response) => response.json())
          .then(async (entry: any) => {
            resolve(entry);
          })
          .catch((e) => reject(e));
        break;
      case FETCH_METHODS.ENTRY:
        fetch("/api/strapi/entry/" + collectionType + "/" + id, {
          method: "post",
          headers: {
            "Content-Type": `application/json`,
          },
          body: JSON.stringify(params),
        })
          .then((response) => response.json())
          .then(async (entry: any) => {
            resolve(entry);
          })
          .catch((e) => reject(e));
        break;

      case FETCH_METHODS.ENTRIES:
        if(body)
          body.params = params;

        fetch("/api/strapi/entries/" + collectionType, {
          method: "post",
          headers: {
            "Content-Type": `application/json`,
          },
          body: JSON.stringify(body || {params}),
        })
          .then((response) => response.json())
          .then(async (entry: any) => {
            resolve(entry);
          })
          .catch((e) => reject(e));
        break;
      default:
        reject(Error("Wrong method"));
        break;
    }
  });
};

export function getLocalStorage(): Storage | null {
  if (storageAvailable()) {
    return localStorage;
  }
  return null;
}

// Function to check if localStorage is available
export function storageAvailable(): boolean {
  try {
    const test = "__storage_test__";
    window.localStorage.setItem(test, test);
    window.localStorage.removeItem(test);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      window.localStorage.length !== 0
    );
  }
}

export function objectToFormData(obj: any) {
  var formData = new FormData();
  for (var key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
}

export interface EventProps {
  value?: number;
  tag?: string;
  event?: any;
  id?: string;
  timestamp?: number;
}

export async function sendEvent({
  id,
  tag = "click",
  event = "hotness",
  value = 1,
  timestamp
}: EventProps) {
  return await fetch("/api/event", {
    headers: {
      "Content-Type": "application/json", // Add this line
    },
    method: "POST",
    body: JSON.stringify({
      event,
      entry: id,
      tag,
      value,
      timestamp
    }),
  }).catch(console.error);
}

export interface SearchProps {
  value?: number;
  query?: string;
  event?: any;
}

export async function sendSearch({
  query = "searchterm",
  event = "main_search",
  value = 1,
}: SearchProps) {
  return await fetch("/api/searchterm", {
    headers: {
      "Content-Type": "application/json", // Add this line
    },
    method: "POST",
    body: JSON.stringify({
      event,
      query,
      value,
    }),
  }).catch(console.error);
}

export function sendCredit({ action = "click" }) {
  try {
    const creditSendClick = localStorage && localStorage.getItem("creditSendClick");
    let delay = 0;

    switch (action) {
      case "click":
        delay = 1;
        break;
      case "view":
        delay = 1;
        break;
      default:
        delay = 1
    }

    const diff =
      (creditSendClick && moment().diff(moment(creditSendClick), "h")) || 0;

    if (!creditSendClick || diff > delay) {
      localStorage &&
      localStorage.setItem("creditSendClick", moment().toISOString());

      fetch("/api/credits", {
        headers: {
          "Content-Type": "application/json", // Add this line
        },
        method: "POST",
        body: JSON.stringify({
          action,
        }),
      }).catch(e => {
        localStorage.removeItem("creditSendClick");
        console.error(e);
      });
    }
  } catch (error) {
    console.error(error);
  }
}
